import styled from 'styled-components';
import colors from '../../lib/colors';

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  
  &:hover {
    color: ${colors.platinum};
  }
`;
