import consts from './consts.js';

class Input {
  constructor(callback) {
    this.callback = callback;

    this.state = {
      actions: {
        [consts.UP]: false,
        [consts.RIGHT]: false,
        [consts.DOWN]: false,
        [consts.LEFT]: false,
        [consts.FIRE]: false,
        [consts.IGNORE]: false,
      },
    };

    window.addEventListener('keydown', this._handleKeyEvent);
    window.addEventListener('keyup', this._handleKeyEvent);
  }

  _getAction = (key) => {
    switch (key) {
      case 'a':
      case 'ArrowLeft':
        return consts.LEFT;
      case 'd':
      case 'ArrowRight':
        return consts.RIGHT;
      case ' ':
        return consts.FIRE;
      default:
        return consts.IGNORE;
    }
  };

  _handleKeyEvent = (e) => {
    if (!e.repeat) {
      const key = e.key;
      const action = this._getAction(key);
      this.state.actions[action] = e.type === 'keydown';
      this.callback(this.state.actions);
    }
  };
}

export default Input;
