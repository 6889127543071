import styled from 'styled-components';
import colors from '../../lib/colors';
import screens from '../../lib/screens';

const Aside = styled.aside`
  background-color: ${colors.yonder};
  grid-row: 3 / -1;
  grid-column: 1 / 4;
  
  @media screen and (max-width: ${screens.smMax}) {
    grid-column: 1 / 6;
  }
  
  @media screen and (max-width: ${screens.lgMax}) {
    grid-column: 1 / 5;
  }
`;

export default Aside;
