import React, { Component } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import colors from './lib/colors';
import Aside from './components/Aside';
import Content from './components/Content';


class App extends Component {
  render() {
    return (
      <div>
        <div className="app" style={{ backgroundColor: colors.platinum, color: colors.platinum }}>
          <Header />
          <Aside />
          <Content />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
