import React from 'react';
import bash from '../../images/tools/bash.png';
import chrome from '../../images/tools/chrome.png';
import css3 from '../../images/tools/css3.png';
import docker from '../../images/tools/docker.png';
import js from '../../images/tools/js.png';
import eslint from '../../images/tools/eslint.png';
import firefox from '../../images/tools/firefox.png';
import git from '../../images/tools/git.png';
import python from '../../images/tools/python.png';
import node from '../../images/tools/node.png';
import prettier from '../../images/tools/prettier.png';
import react from '../../images/tools/react.png';
import redux from '../../images/tools/redux.png';
import webstorm from '../../images/tools/webstorm.png';
import octocat from '../../images/tools/octocat.png';
import Aside from './Aside';
import ToolGrid from './ToolGrid';
import ToolWrapper from './ToolWrapper';
import ToolLink from './ToolLink';
import ToolImage from './ToolImage';
import AsideTitle from './AsideTitle';

const images = [
  { name: 'js', src: js, alt: 'JAVASCRIPT', url: 'https://www.ecma-international.org/ecma-262/9.0/index.html' },
  { name: 'python', src: python, alt: 'PYTHON', url: 'https://www.python.org/' },
  { name: 'node', src: node, alt: 'NODE', url: 'https://nodejs.org/en/' },
  { name: 'react', src: react, alt: 'REACT', url: 'https://reactjs.org/' },
  { name: 'redux', src: redux, alt: 'REDUX', url: 'https://redux.js.org/' },
  { name: 'prettier', src: prettier, alt: 'PRETTIER', url: 'https://prettier.io/' },
  { name: 'eslint', src: eslint, alt: 'ESLINT', url: 'https://eslint.org/' },
  { name: 'bash', src: bash, alt: 'BASH', url: 'https://www.gnu.org/software/bash/' },
  { name: 'git', src: git, alt: 'GIT', url: 'https://git-scm.com/' },
  { name: 'docker', src: docker, alt: 'DOCKER', url: 'https://www.docker.com/' },
  { name: 'webstorm', src: webstorm, alt: 'WEBSTORM', url: 'https://www.jetbrains.com/webstorm/' },
  { name: 'firefox', src: firefox, alt: 'FIREFOX', url: 'https://www.mozilla.org/en-US/firefox/new/' },
  { name: 'chrome', src: chrome, alt: 'CHROME', url: 'https://www.google.com/chrome/' },
  { name: 'github', src: octocat, alt: 'GIT HUB', url: 'https://github.com/' },
  { name: 'css3', src: css3, alt: 'CSS GRID', url: 'https://www.w3.org/standards/techs/css#w3c_all' },
];

const renderImages = () => {
  return images.map(image => {
    return (
      <ToolWrapper>
        <ToolLink
          href={image.url}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          <ToolImage
            src={image.src}
            alt={image.alt}
            title={image.alt}
          />
          <br />
          <span>{image.alt}</span>
        </ToolLink>
      </ToolWrapper>
    );
  });
};

export default () => {
  return (
    <Aside>
      <AsideTitle>FAVORITE TOOLS</AsideTitle>
      <ToolGrid>
        {renderImages()}
      </ToolGrid>
    </Aside>
  );
};
