class Sprite {
  constructor(imageCache, ctx, config) {
    this.imageCache = imageCache;
    this.ctx = ctx;
    this.tag = config.tag;
    this.startPos = config.startPosition || { x: 0, y: 0 };
    this.sheetSize = config.sheetSize;
    this.loop = config.loop === undefined ? true : config.loop;
    this.frameCount = config.frameCount || 1;
    this.ticksPerFrame = config.ticksPerFrame;

    this.state = {
      playSingle: false,
      pos: this.startPos,
      frameIndex: 0,
      index: 0,
      ticks: 0,
      visible: this.loop,
    };
  }

  play = () => {
    this.state.visible = true;
    this.state.playSingle = true;
  };
  hide = () => this.state.visible = false;
  show = () => this.state.visible = true;

  visible = () => this.state.visible;

  position = () => this.state.pos;

  move = (amount) => {
    this.state.pos = { x: this.state.pos.x + amount.x, y: this.state.pos.y + amount.y } ;
  };

  moveTo = (position) => this.state.pos = position;

  _updateFrame = () => {
    const { ticksPerFrame, frameCount } = this;
    this.state.ticks += 1;

    if (this.state.ticks > ticksPerFrame) {
      this.state.ticks = 0;
      if (this.loop) {
        this.state.frameIndex < frameCount - 1 ? this.state.frameIndex += 1 : this.state.frameIndex = 0;
      } else if (this.state.playSingle) {
        if (this.state.frameIndex < frameCount - 1) {
          this.state.frameIndex += 1
        } else {
          this.state.frameIndex = 0;
          this.state.playSingle = false;
          this.hide();
        }
      }
    }
  };

  _draw = () => {
    const { frameCount, tag, sheetSize: { w, h } } = this;
    this.ctx.drawImage(
      this.imageCache[tag],
      this.state.frameIndex * (w / frameCount),
      0,
      w / frameCount,
      h,
      this.state.pos.x,
      this.state.pos.y,
      w / frameCount,
      h,
    );
  };


  render = () => {
    this._updateFrame();
    if (this.state.visible) {
      this._draw(this.imageCache, this.ctx);
    }
  };
}

export default Sprite;
