import styled from 'styled-components';
import colors from '../../lib/colors';

const footerWrapper = styled.footer`
  background-color: ${colors.rusty};
  color: white;
  grid-column: 1 / -1;
  grid-row: -2 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vh;
`;

export default footerWrapper;
