import Base from './Base.js';

class Outpost {
  constructor(ctx, canvas) {
    this.canvas = canvas;
    this.baseLocations = [250, 550, 850];
    this.bases = [];

    this.baseLocations.forEach(baseX => {
      const baseY = this.canvas.height - 180;
      this.bases.push(new Base(ctx, {
        topLeft: { x: baseX, y: baseY },
        width: 20,
        height: 10,
        color: '#D7263D',
        blockSize: 4
      }))
    })
  }

  removeBlock = (i, j, k) => {
    this.bases[i].removeBlock(j, k);
  };

  bases = () => this.bases;

  reset = () => this.bases.forEach(base => base.init());

  render = () => {
    this.bases.forEach(base => base.render());
  };
}

export default Outpost;
