import Bomb from './Bomb.js';

class Bombardment {
  constructor(imageCache, ctx, canvas, armada, player, killPlayer, outpost) {
    this.imageCache = imageCache;
    this.ctx = ctx;
    this.canvas = canvas;
    this.armada = armada;
    this.player = player;
    this.xOffset = 14;
    this.yOffset = 32;
    this.killPlayer = killPlayer;
    this.outpost = outpost;

    this.state = {
      ticks: 0,
      tickPerLaunch: 20,
      bombs: [],
    }
  }

  checkPlayerCollision = (bomb) => {
    const { player } = this;
    const xDiff = bomb.sprite.position().x - player.sprite.position().x;
    const yDiff = player.sprite.position().y - bomb.sprite.position().y;
    const xAlign = xDiff > 0 && xDiff < this.player.width();
    const yAlign = yDiff > 0 && yDiff < this.player.height();

    if (xAlign && yAlign) { this.killPlayer(); }

    return xAlign && yAlign;
  };

  checkBaseCollision = (bomb) => {
    let collision = false;
    this.outpost.bases.forEach((base, i) => {
      base.blockLists.forEach((list, j) => {
        list.forEach((block, k) => {
          const bombPosition = bomb.sprite.position();
          const xDiff = Math.abs(block.x - bombPosition.x);
          const yDiff = block.y - bombPosition.y;
          const xAlign = xDiff < 5;
          const yAlign = yDiff > 0 && yDiff < 32;
          if (xAlign && yAlign) {
            this.outpost.removeBlock(i, j, k);
            collision = true;
          }
        });
      })
    });
    return collision;
  };

  update = () => {
    this.state.ticks += 1;
    if (this.state.ticks >= this.state.tickPerLaunch) {
      this.state.ticks = 0;
      const canFire = this.armada.canFire();

      if (this.state.bombs.length < canFire.length) {
        const range = canFire.length;
        const random = Math.floor(range * Math.random());
        const position = { x: canFire[random].x + this.xOffset, y: canFire[random].y + this.yOffset };

        this.state.bombs.push(new Bomb(this.imageCache, this.ctx, position));
      }
    }
    // remove bombs that fall off screen
    this.state.bombs = this.state.bombs.filter(bomb => {
      bomb.update();
      const baseCollision = this.checkPlayerCollision(bomb);
      const playerCollision = this.checkBaseCollision(bomb);
      const collision = playerCollision || baseCollision;

      this.checkBaseCollision(bomb);
      return ((bomb.sprite.position().y < this.canvas.height) && !collision);
    });
  };

  reset = () => this.state.bombs = [];

  render = () => {
    this.state.bombs.forEach(bomb => bomb.render());
  };

}

export default Bombardment;
