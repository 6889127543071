import React from 'react';
import logo from '../../images/logo.svg';
import Img from './Img';
import Wrapper from './Wrapper';

export default () => {
  return (
    <Wrapper>
      <Img src={logo} alt="jerimiahmair.com logo"/>
    </Wrapper>
  );
};
