import styled from 'styled-components';
import colors from '../../lib/colors';

const Wrapper = styled.header`
  background-color: ${colors.gunmetal};
  color: ${colors.maya};
  grid-column: 1 / -1;
  grid-row: 1 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2vh;
`;

export default Wrapper;
