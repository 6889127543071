import Input from './Input.js';
import Player from './Player.js';
import Armada from './Armada.js';
import Laser from './Laser.js';
import Explosion from './Explosion.js';
import Score from './Score.js';
import Dialog from './Dialog.js';
import Bombardment from './Bombardment.js';
import PlayerLives from './PlayerLives.js';
import Outpost from './Outpost.js';

class Game {
  constructor(canvas, imageCache, options) {
    this.canvas = canvas;
    this.ctx = canvas.getContext('2d');
    this.width = canvas.width || 1200;
    this.height = canvas.height || 800;
    this.backgroundColor = options.backgroundColor;
    this.imageCache = imageCache;
    this.player = undefined;
    this.armada = undefined;
    this.laser = undefined;
    this.maxDeadTicks = 100;
    this.initialPlayerLives = 3;

    this.state = {
      playerDead: false,
      playerInput: {},
      sprites: [],
      start: undefined,
      gameStarted: false,
      gameOver: false,
      playerDeadTicks: 0,
      playerCurrentLives: this.initialPlayerLives,
    }
  }

  init = () => {
    this._buildParts();
    new Input(this._handleInput);
  };

  _handleInput = (playerInput) => {
    if (playerInput.FIRE) {
      if (this.state.gameStarted && !this.state.playerDead) {
        this.laser.fire();
      } else {
        this.state.gameStarted = true;
        this.score.reset();
      }
    }
    this.state.playerInput = playerInput;
  };

  _buildParts = () => {
    const { imageCache, ctx, canvas } = this;
    this.outpost = new Outpost(ctx, canvas);
    this.armada = new Armada(imageCache, ctx, canvas, this._gameOver, this.outpost.reset);
    this.explosion = new Explosion(imageCache, ctx, canvas);
    this.laser = new Laser(imageCache, ctx, this.outpost);
    this.player = new Player(imageCache, ctx, canvas);
    this.score = new Score(ctx);
    this.startDialog = new Dialog(ctx, canvas, ['Press [Space] to start game.']);
    this.gameOverDialog = new Dialog(ctx, canvas, ['Game Over!', 'Press [Space] to try again.']);
    this.bombardment = new Bombardment(imageCache, ctx, canvas, this.armada, this.player, this.killPlayer, this.outpost);
    this.playerLives = new PlayerLives(imageCache, ctx, canvas);

    this.loop();
  };

  _gameOver = () => {
    this.state.gameStarted = false;
    this.state.gameOver = true;
    this.state.playerCurrentLives = this.initialPlayerLives;
    this.armada.reset();
    this.bombardment.reset();
    this.outpost.reset();
  };

  _paintBg = () => {
    const { backgroundColor, ctx, width, height } = this;
    ctx.clearRect(0, 0, width, height);
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, width, height);
  };

  _renderOverlay = () => {
    const { ctx, width, height } = this;
    ctx.fillStyle = 'transparent';
    ctx.fillRect(0, 0, width, height);
  };

  _playerDeadPause = () => {
    this.state.playerDeadTicks += 1;

    if (this.state.playerDeadTicks > this.maxDeadTicks) {
      this.state.playerDeadTicks = 0;
      this.state.playerDead = false;
      this.player.sprite.show();
    }
  };

  _update = () => {
    if (this.state.playerDead) {
      if (this.state.playerCurrentLives <= 0) {
        this._gameOver();
      }
      this._playerDeadPause();
    } else {
      const playerCannon = { x: this.player.position().x + this.player.width() / 2, y: this.player.position().y };

      this.laser.update(playerCannon, this.armada, this.explosion, this.score);
      this.player.update(this.state.playerInput);
      this.armada.update();
      this.bombardment.update();
    }
  };

  _render = () => {
    this._paintBg();
    this.explosion.render();
    this.laser.render();
    this.player.render();
    this.armada.render();
    this.bombardment.render();
    this.playerLives.render(this.state.playerCurrentLives);
    this.outpost.render();

    if (!this.state.gameStarted) {
      this._renderOverlay();
      if (!this.state.gameOver) { this.startDialog.render(); }
      else { this.gameOverDialog.render() }
    }

    this.score.render();
  };

  killPlayer = () => {
    this.state.playerCurrentLives -= 1;
    this.state.playerDead = true;
    this.player.sprite.hide();
    this.explosion.sprite().moveTo(this.player.sprite.position());
    this.explosion.sprite().play();
  };

  pause = () => this.state.paused = true;

  resume = () => this.state.paused = false;

  loop = (timestamp) => {
    if (!this.state.start) { this.state.start = timestamp }
    if (timestamp - this.state.start > 1) { // ensure consistent time
      this.state.start = timestamp;

      if (this.state.gameStarted) { this._update() }
      this._render();
    }

    window.requestAnimationFrame(this.loop);
  };
}

export default Game;
