import Sprite from './Sprite.js';

class Bomb {
  constructor(imageCache, ctx, location) {
    this.speed = 4;
    this.direction = { x: 0, y: this.speed };
    this.options = {
      startPosition: location,
      tag: 'bomb',
      sheetSize: { w: 4, h: 32 },
      frameCount: 1,
      ticksPerFrame: 1,
    };
    this.sprite = new Sprite(imageCache, ctx, this.options);
  }

  update = () => {
    this.sprite.move(this.direction);
  };

  render = () => {
    this.sprite.render();
  };
}

export default Bomb;
