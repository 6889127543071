import Sprite from './Sprite.js';

class PlayerLives {
  constructor(imageCache, ctx, canvas) {
    this.imageCache = imageCache;
    this.ctx = ctx;
    this.canvas = canvas;
    this.config = {
      tag: 'hero',
      sheetSize: { w: 64, h: 24 },
      frameCount: 1,
      ticksPerFrame: 1,
    };
    this.maxLives = 10;
    this.sprites = [];

    for (let i = 1; i < this.maxLives + 1; i++) {
      const sheetWidth = this.config.sheetSize.w ;
      const spacer = 8;
      const instanceX = (sheetWidth + spacer) * i;

      const config = Object.assign({}, this.config,
        { startPosition: { x: instanceX, y: this.canvas.height - 32 } });

      this.sprites.push(new Sprite(this.imageCache, this.ctx, config));
    }
  }

  render = (lives) => {
    const toRender = lives < this.maxLives ? lives : this.maxLives;
    for (let i = 1; i < toRender; i++) {
      this.sprites[i].render();
    }
  };
}

export default PlayerLives;
