import React, {Component} from 'react';
import ContentWrapper from './ContentWrapper';
import Main from './Main';
import alien1 from '../../images/invaders/alien1.png';
import alien2 from '../../images/invaders/alien2.png';
import alien3 from '../../images/invaders/alien3.png';
import bomb from '../../images/invaders/bomb.png';
import explosion from '../../images/invaders/explosion.png';
import hero from '../../images/invaders/hero.png';
import laser from '../../images/invaders/laser.png';
import Game from '../../lib/invaderClasses/Game';

class Content extends Component {
  constructor(props) {
    super(props);

    this.canvas = null;
    this.ctx = null;
    this.imageCache = {};

    this.images = [
      { name: 'alien1', src: alien1 },
      { name: 'alien2', src: alien2 },
      { name: 'alien3', src: alien3 },
      { name: 'bomb', src: bomb },
      { name: 'explosion', src: explosion },
      { name: 'hero', src: hero },
      { name: 'laser', src: laser },
    ];

    this.state = {
      gameOn: true
    }
  }

  checkLoadCount = () => {
    if (Object.keys(this.imageCache).length === this.images.length) {
      const gameOptions = { backgroundColor: 'transparent' };
      const game = new Game(this.canvas, this.imageCache, gameOptions);
      game.init();
    }
  };

  componentDidMount() {
    this.canvas = this.refs.canvas;
    this.ctx = this.canvas.getContext('2d');
    this.images.forEach(image => {
      const target = this.refs[image.name];
      this.imageCache[image.name] = target;
      target.onload = this.checkLoadCount();
    });
  }

  createHiddenImages = () => {
    return this.images.map(image => <img src={image.src} key={image.name} ref={image.name} alt={image.name}
                                         style={{ display: 'none' }} />)
  };

  render() {
  return (
      <ContentWrapper>
        <Main>
          <canvas
              className="canvas"
              ref="canvas"
              width={1200}
              height={600}
            />
          {this.createHiddenImages()}
        </Main>
      </ContentWrapper>
    );
  }
};

export default Content;
