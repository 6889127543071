import styled from 'styled-components';
import colors from '../../lib/colors';
import screens from '../../lib/screens';

const ContentWrapper = styled.div`
  background-color: ${colors.platinum};
  color: ${colors.gunmetal};
  grid-row: 3 / -2;
  grid-column: 4 / -1;
  padding: 4vh;
  
  @media screen and (max-width: ${screens.xlMin}) {
    grid-column: 5 / -1;
  }
  
  @media screen and (max-width: ${screens.smMin}) {
    grid-column: 6 / -1;
  }
`;

export default ContentWrapper;
