import styled from 'styled-components';
import screens from '../../lib/screens';

const ToolGrid = styled.div`
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  grid-gap: 2vh;
  
  @media screen and (max-width: ${screens.smMax}) {
    display: grid;
    grid-template: auto / repeat(2, 1fr);
    grid-gap: 2vh;
  }
  
  @media screen and (max-width: ${screens.xsMax}) {
    grid-template: auto / 1fr;
    grid-gap: 2vh;
  }
`;

export default ToolGrid;
