export default {
  maya: '#56CFFF',
  yonder: '#547aa5',
  mud: '#50514F',
  gunmetal: '#293132',
  platinum: '#dbdbdb',
  sand: '#EDD4B2',
  tomato: '#F06543',
  rusty: '#D7263D',
  ufo: '#23CE6B',
};
