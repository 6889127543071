import Sprite from './Sprite.js';

class Player {
  constructor(imageCache, ctx, canvas) {
    this.canvas = canvas;
    this.options = {
      startPosition: { x: canvas.width / 2 - 16, y: canvas.height - 64 },
      tag: 'hero',
      sheetSize: { w: 64, h: 24 },
      frameCount: 1,
      ticksPerFrame: 1,
    };
    this.speed = 3;
    this.padding = 100;
    this.sprite = new Sprite(imageCache, ctx, this.options);
  }

  _checkBounds = () => {
    const currXPos = this.sprite.position().x;
    return { left: currXPos > this.padding, right: currXPos < this.canvas.width - this.padding };
  };

  _getVector = (input) => {
    const bounds = this._checkBounds();
    let vector = { x: 0, y: 0 };

    if (input.RIGHT) {
      if (bounds.right) {
        vector.x = this.speed;
      }
    } else if (input.LEFT) {
      if (bounds.left) {
        vector.x = this.speed * -1;
      }
    }
    return vector;
  };

  position = () => this.sprite.position();

  width = () => this.options.sheetSize.w;
  height = () => this.options.sheetSize.h;

  update = (playerInput) => {
    this.sprite.move(this._getVector(playerInput));
  };

  render = () => {
    this.sprite.render();
  };
}

export default Player;
