class Score {
  constructor(ctx) {
    this.ctx = ctx;
    this.location = { x: 20, y: 40 };
    this.font = 'bold 24px \'Fjalla One\'';

    this.state = {
      score: 0,
      text: 'SCORE:',
    };
  }

  update = (amount) => {
    this.state.score += amount;
  };

  reset = () => {
    this.state.score = 0;
  };

  render = () => {
    const { ctx, font, location } = this;
    ctx.font = font;
    ctx.fillStyle = 'white';
    ctx.fillText(`${this.state.text} ${this.state.score}`, location.x, location.y);
  };
}

export default Score;
