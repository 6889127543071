class Base {
  constructor(ctx, options) {
    this.ctx = ctx;
    this.blockSize = options.blockSize;
    this.topLeft = options.topLeft;
    this.color = options.color;
    this.width = options.width;
    this.height = options.height;
    this.blockLists = [];

    this.init();
  }

  init = () => {
    const lists = [];
    for (let i = 0; i < this.height; i++) {
      const arr = [];
      for (let j = 0; j < this.width; j++) {
        arr.push({
          x: this.topLeft.x + j * this.blockSize,
          y: this.topLeft.y + i * this.blockSize,
        });
      }
      lists.push(arr);
    }
    this.blockLists = lists;
  };

  blockList = () => this.blockList;

  removeBlock = (j, k) => {
    this.blockLists[j].splice(k, 4);
    if (j + 1 < this.blockLists.length) this.blockLists[j + 1].splice(k, 4);
    if (j + 2 < this.blockLists.length) this.blockLists[j + 2].splice(k, 4);
  };

  render = () => {
    const { blockSize, color } = this;
    this.ctx.fillStyle = color;

    this.blockLists.forEach((list, i) => {
      list.forEach((block, j) => {
        this.ctx.fillRect(block.x, block.y, blockSize, blockSize);
      });
    });
  };
}

export default Base;
