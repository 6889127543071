export default {
  xsMax: '575.98px',
  xsMin: '576px',
  smMax: '767.98px',
  smMin: '768px',
  mdMax: '991.98px',
  mdMin: '992px',
  lgMax: '1199.98px',
  xlMin: '1200px',
};
