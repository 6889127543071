import React from 'react';
import Logo from '../Logo';
import Wrapper from './Wrapper';
import H1 from './H1';

export default ({ handleClick, invade }) => {
  return (
    <Wrapper>
      <Logo /><H1>Jerimiah Mair</H1>
    </Wrapper>
  );
}
