import Sprite from './Sprite.js';

class Laser {
  constructor(imageCache, ctx, outpost) {
    this.outpost = outpost;
    this.config = {
      startPosition: { x: -100, y: -100 },
      tag: 'laser',
      sheetSize: { w: 4, h: 24 },
      frameCount: 1,
      ticksPerFrame: 1,
    };
    this.sprite = new Sprite(imageCache, ctx, this.config);

    this.state = {
      moving: false,
    };
  }

  fire = () => {
    this.sprite.show();
    this.state.moving = true;
  };

  moveTo = (position) => {
    this.sprite.moveTo(position);
  };

  _moveOrResetToPlayer = (playerCannon) => {
    if (this.state.moving) {
      this.sprite.move({ x: 0, y: -20 });
    } else { // if it's not moving reset it to the player's position
      this.sprite.hide();
      this.moveTo(playerCannon);
    }
  };

  _checkIfOffScreen = () => {
    if (this.sprite.position().y < 0) {
      this.state.moving = false;
    }
  };

  _checkArmadaCollision = (armada, explosion, score) => {
    const laser = this.sprite.position();
    const shipSquare = 32;

    armada.positions().forEach((position, index) => {
      const xDiff = laser.x - position.x;
      const yDiff = laser.y - position.y;
      const xAlign = xDiff > 0 && xDiff < shipSquare;
      const yAlign = yDiff > 0 && yDiff < shipSquare;

      if (xAlign && yAlign) {
        if (armada.ships[index].sprite.visible()) {  // do not collide with hidden ships
          score.update(armada.ships[index].points);
          this.state.moving = false;
          armada.hideShip(index);
          explosion.sprite().moveTo(position);
          explosion.sprite().play();
        }
      }
    });
  };

  _checkForBaseCollision = () => {
    let collision = false;
    this.outpost.bases.forEach((base, i) => {
      base.blockLists.forEach((list, j) => {
        list.forEach((block, k) => {
          const laserPosition = this.sprite.position();
          const xDiff = Math.abs(block.x - laserPosition.x);
          const yDiff = block.y - laserPosition.y;
          const xAlign = xDiff < 5;
          const yAlign = yDiff > 0 && yDiff < 32;
          if (xAlign && yAlign) {
            this.outpost.removeBlock(i, j, k);
            collision = true;
          }
        });
      })
    });
    return collision;
  };

  update = (playerCannon, armada, explosion, score) => {
    this._checkArmadaCollision(armada, explosion, score);
    this._checkIfOffScreen();
    this._moveOrResetToPlayer(playerCannon);
    this._checkForBaseCollision();
  };

  render = () => {
    this.sprite.render();
  };
}

export default Laser;
