import React from 'react';
import linkedIn from '../../images/tools/linkedIn.png';
import gitHub from '../../images/tools/gitHub.png';
import FooterWrapper from './FooterWrapper';
import FooterImg from './FooterImg';

const year = () => {
  const date = new Date();
  return date.getFullYear();
};

export default () => {
  return (
    <FooterWrapper>
      <h4>©{year()} Jerimiah Mair</h4>
      <a
        href="https://www.linkedin.com/in/jerimiah-mair-a040029a/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FooterImg src={linkedIn} alt="LinkedIn" className="footer-img"/>
      </a>
      <a
        href="https://github.com/jmair"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FooterImg src={gitHub} alt="Git Hub" className="footer-img"/>
      </a>
    </FooterWrapper>
  );
};
