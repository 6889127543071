import colors from '../../lib/colors';

class Dialog {
  constructor(ctx, canvas, text) {
    this.canvas = canvas;
    this.ctx = ctx;
    this.fontSize = 48;
    this.font = `bold ${this.fontSize}px 'Fjalla One'`;

    this.state = {
      text,
    }
  }

  render = () => {
    const { canvas, ctx, font, fontSize } = this;

    this.state.text.forEach((line, index) => {
      const xOffset = line.length / 2 * this.fontSize * .4;
      const yOffset = index * fontSize * 1.7;
      const location = { x: canvas.width / 2 - xOffset, y: canvas.height - 100 - yOffset };

      ctx.font = font;
      ctx.fillStyle = colors.gunmetal;
      ctx.fillText(line, location.x, location.y);
    });
  };
}

export default Dialog;
